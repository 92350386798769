body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.item {
  position: relative;
  margin: 10px;
  cursor: pointer;
  width: 144px;
}

.item.selected {
  outline: 2px solid #1890ff;
  outline-offset: 5px;
}

.cover {
  width: 100%;
  padding-top: 56.25%;
  background-color: #1c1e26;
  background-repeat: no-repeat;
  background-position: 50%;
  background-size: contain;
}

.duration {
  position: absolute;
  top: 4px;
  right: 4px;
  background-color: rgba(0, 0, 0, 0.5);
  color: #eeeeee;
}

.title {
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.ant-tabs.ant-tabs-top {
  overflow: auto;
}